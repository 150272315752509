import { GatsbyBrowser } from 'gatsby'

import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { localStorageService } from '../../src/services/localStorageService'

enum ReleaseStage {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

const releaseStage = (): ReleaseStage => {
  if (window.location.host.includes('localhost')) {
    return ReleaseStage.Development
  } else if (window.location.host.includes('deploy-preview')) {
    return ReleaseStage.Staging
  }
  return ReleaseStage.Production
}

Bugsnag.start({
  apiKey: 'd6b8b8a410c915c1db511525def6ead5',
  plugins: [new BugsnagPluginReact()],
  releaseStage: releaseStage(),
  enabledReleaseStages: [ReleaseStage.Staging, ReleaseStage.Production],
  onError: () => {
    const cookiesPermissions = localStorageService.getItem(
      'rentel:cookiePermission'
    )
    return cookiesPermissions ? cookiesPermissions.allowsCookies : true
  },
})

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

export const addCrashReporter: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => <ErrorBoundary>{element}</ErrorBoundary>
