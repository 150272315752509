exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ostetaan-asuntoja-lahti-tsx": () => import("./../../../src/pages/ostetaan-asuntoja-lahti.tsx" /* webpackChunkName: "component---src-pages-ostetaan-asuntoja-lahti-tsx" */),
  "component---src-pages-tietosuojaseloste-tsx": () => import("./../../../src/pages/tietosuojaseloste.tsx" /* webpackChunkName: "component---src-pages-tietosuojaseloste-tsx" */),
  "component---src-pages-virtuaalisisustus-index-tsx": () => import("./../../../src/pages/virtuaalisisustus/index.tsx" /* webpackChunkName: "component---src-pages-virtuaalisisustus-index-tsx" */),
  "component---src-pages-virtuaalisisustus-tilaa-tsx": () => import("./../../../src/pages/virtuaalisisustus/tilaa.tsx" /* webpackChunkName: "component---src-pages-virtuaalisisustus-tilaa-tsx" */),
  "component---src-pages-vuokravalitys-helsinki-tsx": () => import("./../../../src/pages/vuokravalitys-helsinki.tsx" /* webpackChunkName: "component---src-pages-vuokravalitys-helsinki-tsx" */),
  "component---src-templates-apartment-page-tsx": () => import("./../../../src/templates/apartmentPage.tsx" /* webpackChunkName: "component---src-templates-apartment-page-tsx" */),
  "component---src-templates-application-page-tsx": () => import("./../../../src/templates/applicationPage.tsx" /* webpackChunkName: "component---src-templates-application-page-tsx" */)
}

