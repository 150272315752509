type RentelLocalStorage = {
  'rentel:cookiePermission': {
    setAt: number
    allowsCookies: boolean
  }
}

export const localStorageService = {
  getItem<T extends keyof RentelLocalStorage>(
    key: T
  ): RentelLocalStorage[T] | undefined {
    const rawValue = localStorage.getItem(key)

    return rawValue ? JSON.parse(rawValue) : undefined
  },
  setItem<T extends keyof RentelLocalStorage>(
    key: T,
    value: RentelLocalStorage[T]
  ) {
    localStorage.setItem(key, JSON.stringify(value))
  },
}
